import { FormLabelSkeleton } from "@/components/forms/FormLabel";
import SelectInputSkeleton from "@/components/forms/SelectInputSkeleton";
import TextInputSkeleton from "@/components/forms/TextInputSkeleton";

type DeliveryFormCarrierHomeSkeletonProps = {
  withDetails?: boolean;
};

const DeliveryFormCarrierHomeSkeleton = ({
  withDetails = false,
}: DeliveryFormCarrierHomeSkeletonProps) => {
  return (
    <>
      {withDetails && (
        <>
          <div className="col-span-6">
            <FormLabelSkeleton />
            <div className="mt-3">
              <TextInputSkeleton />
            </div>
          </div>
          <div className="col-span-6">
            <FormLabelSkeleton />
            <div className="mt-3">
              <TextInputSkeleton />
            </div>
          </div>
          <div className="col-span-2">
            <FormLabelSkeleton />
            <div className="mt-3">
              <TextInputSkeleton />
            </div>
          </div>
          <div className="col-span-4">
            <FormLabelSkeleton />
            <div className="mt-3">
              <TextInputSkeleton />
            </div>
          </div>
          <div className="col-span-6">
            <FormLabelSkeleton />
            <div className="mt-3">
              <TextInputSkeleton />
            </div>
          </div>
          <div className="col-span-6">
            <FormLabelSkeleton />
            <div className="mt-3">
              <TextInputSkeleton className="h-[78px]" />
            </div>
          </div>
          <div className="col-span-6">
            <FormLabelSkeleton />
            <div className="mt-3">
              <TextInputSkeleton className="h-[58.25px]" />
            </div>
          </div>
          <div className="col-span-6 py-2">
            <h3 className="text-lg font-semibold leading-6 text-vert-800">
              <div className="h-8 bg-gray-200 rounded-full w-48 animate-pulse" />
            </h3>
          </div>
        </>
      )}
      <div className="col-span-6">
        <FormLabelSkeleton />
        <div className="mt-3">
          <SelectInputSkeleton />
        </div>
      </div>

      <div className="col-span-6">
        <FormLabelSkeleton />
        <div className="mt-3">
          <SelectInputSkeleton />
        </div>
      </div>
    </>
  );
};

export default DeliveryFormCarrierHomeSkeleton;
