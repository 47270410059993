"use client";

import { FormLabelSkeleton } from "@/components/forms/FormLabel";
import SelectInputSkeleton from "@/components/forms/SelectInputSkeleton";

const DeliveryFormLocationSkeleton = () => {
  return (
    <>
      <div className="col-span-3">
        <FormLabelSkeleton />
        <div className="mt-2">
          <SelectInputSkeleton />
        </div>
      </div>

      <div className="col-span-3">
        <FormLabelSkeleton />
        <div className="mt-2">
          <SelectInputSkeleton />
        </div>
      </div>
    </>
  );
};

export default DeliveryFormLocationSkeleton;
