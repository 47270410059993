"use client";

import { FormLabelSkeleton } from "@/components/forms/FormLabel";

const mapInputHeight = `400px`;

const DeliveryFormCarrierDropOffPointSkeleton = () => {
  const Items = Array.from(Array(4).keys()).map((key) => {
    return (
      <div
        key={key}
        className="relative text-left flex flex-col rounded-lg bg-white p-4 shadow-sm border w-full animate-pulse"
      >
        <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
        <div className="h-2 bg-gray-200 rounded-full max-w-[360px] mb-2.5"></div>
        <div className="h-2 bg-gray-200 rounded-full max-w-[360px] mb-2.5"></div>
      </div>
    );
  });
  return (
    <div className="col-span-6">
      <div className="flex justify-between items-center">
        <FormLabelSkeleton />
        <div className="h-8 bg-gray-200 rounded-md w-12 animate-pulse"></div>
      </div>
      <div
        style={{
          minHeight: mapInputHeight,
        }}
        className="w-full flex flex-col gap-y-2 mt-2"
      >
        {Items}
      </div>
    </div>
  );
};

export default DeliveryFormCarrierDropOffPointSkeleton;
