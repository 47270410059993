import { format, isAfter, isSameDay, parse, parseISO } from "date-fns";
import { fr, nl } from "date-fns/locale";

import OptionType from "@/types/OptionType";

import { OrderType } from "@efarmz/efarmz-domain-typescript";

import getNextSubscriptionGenerationDate from "@/utils/shared/getNextSubscriptionGenerationDate";

const getDeliveryDataFromDeliveryOptions = ({
  locale,
  shortDeliveryDate,
  longDeliveryDate,
  deliveryOptions,
  countryIso2,
  zipcode,
  orderType,
}: {
  locale: string;
  shortDeliveryDate: Date;
  longDeliveryDate: Date;
  deliveryOptions: any;
  countryIso2: string;
  zipcode: string;
  orderType: OrderType;
}) => {
  const carriers =
    deliveryOptions?.carriers?.length > 0
      ? deliveryOptions?.carriers.filter((carrier: any) =>
          carrier?.postcodes.includes(
            countryIso2 === `BE` ? zipcode : `L-${zipcode}`
          )
        )
      : [];

  const dateFormat =
    orderType === OrderType.SUBSCRIPTION ? `EEEE dd/MM` : `EEEE dd/MM/yyyy`;

  const carrierByDeliveryDate: any = {};
  carriers.forEach((carrier: any) => {
    carrier.availabilities?.forEach((availabilitiy: any) => {
      try {
        const start = parseISO(availabilitiy.start);
        const key = format(start, `dd/MM/yyyy`);
        if (carrierByDeliveryDate.hasOwnProperty(key)) {
          carrierByDeliveryDate[key].push(carrier);
        } else {
          carrierByDeliveryDate[key] = [carrier];
        }
      } catch (e) {}
    });
  });

  let dateOptionsArray: string[] = Object.keys(carrierByDeliveryDate);

  const nextGenerationDate = getNextSubscriptionGenerationDate(`long`);

  let isShortDeliveryTitleSet = false;
  let isLongDeliveryTitleSet = false;

  let dateOptions: OptionType<string>[] = dateOptionsArray
    .filter((key) => {
      const date = parse(key, `dd/MM/yyyy`, new Date());
      if (orderType === OrderType.ONESHOT) {
        return (
          isAfter(date, shortDeliveryDate) || isSameDay(date, shortDeliveryDate)
        );
      } else {
        return (
          isAfter(date, longDeliveryDate) || isSameDay(date, nextGenerationDate)
        );
      }
    })
    .sort(function (a, b) {
      const dateA = parse(a, `dd/MM/yyyy`, new Date());
      const dateB = parse(b, `dd/MM/yyyy`, new Date());
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return dateA.getTime() - dateB.getTime();
    })
    .map((key) => {
      const date = parse(key, `dd/MM/yyyy`, new Date());

      let subtitle = undefined;
      if (orderType === OrderType.ONESHOT) {
        if (
          !isShortDeliveryTitleSet &&
          (isSameDay(date, shortDeliveryDate) ||
            isAfter(date, shortDeliveryDate))
        ) {
          subtitle =
            locale === `fr` ? `1ère livraison shop` : `1e leveringsdatum`;
          isShortDeliveryTitleSet = true;
        } else if (
          !isLongDeliveryTitleSet &&
          (isSameDay(date, longDeliveryDate) || isAfter(date, longDeliveryDate))
        ) {
          subtitle =
            locale === `fr`
              ? `1ère livraison box repas`
              : `1e levering maaltijdbox`;
          isLongDeliveryTitleSet = true;
        }
      }

      const label = format(date, dateFormat, {
        locale: locale === `nl` ? nl : fr,
      });

      return {
        value: key,
        label: label.charAt(0).toUpperCase() + label.slice(1),
        subtitle: subtitle,
      } as OptionType<string>;
    });

  /*
  if (orderType === `subscription` && dateOptions?.length > 0) {
    const lastDisplayDate = addDays(
      parse(dateOptions[0]?.value, `dd/MM/yyyy`, new Date()),
      7
    );

    dateOptions = dateOptions.filter((dateOption) => {
      const date = parse(dateOption.value, `dd/MM/yyyy`, new Date());
      return isBefore(date, lastDisplayDate);
    });
  }*/

  return {
    carriers,
    carrierByDeliveryDate,
    dateOptions,
  };
};

export default getDeliveryDataFromDeliveryOptions;
