import {
  addDays,
  isFriday,
  isMonday,
  isSaturday,
  isSunday,
  isThursday,
  isTuesday,
  isWednesday,
  isWeekend,
  nextFriday,
  nextMonday,
  nextThursday,
  nextTuesday,
  nextWednesday,
} from "date-fns";

const getNextSubscriptionGenerationDate = (
  type: `long` | `short` = `long`
): Date => {
  const date = new Date();

  if (type === `long`) {
    if (isWeekend(date)) {
      return nextWednesday(date);
    } else {
      if (date.getHours() >= 8) {
        if (isMonday(date) || isTuesday(date)) {
          return nextFriday(date);
        } else if (isWednesday(date) || isThursday(date)) {
          return nextMonday(date);
        } else if (isFriday(date)) {
          return nextWednesday(date);
        }
      } else {
        if (isMonday(date) || isWednesday(date) || isFriday(date)) {
          return date;
        } else if (isTuesday(date)) {
          return nextFriday(date);
        } else if (isThursday(date)) {
          return nextMonday(date);
        }
      }
    }
  } else {
    if (isSaturday(date)) {
      return nextMonday(date);
    }

    if (date.getHours() >= 8) {
      if (isFriday(date) || isThursday(date)) {
        return nextMonday(date);
      } else if (isSunday(date)) {
        return nextTuesday(date);
      } else if (isMonday(date)) {
        return nextWednesday(date);
      } else if (isTuesday(date)) {
        return nextThursday(date);
      } else if (isWednesday(date)) {
        return nextFriday(date);
      }
    } else {
      if (isFriday(date) || isSaturday(date)) {
        return nextMonday(date);
      } else {
        return addDays(date, 1);
      }
    }
  }

  throw new Error(`Invalid date ${date}`);
};

export default getNextSubscriptionGenerationDate;
