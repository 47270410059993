import React from "react";

import { twMerge } from "tailwind-merge";

type FormLabelProps = {
  className?: string;
  children: React.ReactNode;
  htmlFor?: string;
};

const FormLabel = ({ className, htmlFor, children }: FormLabelProps) => {
  return (
    <label
      htmlFor={htmlFor}
      className={twMerge(
        `block text-sm font-medium leading-6 text-vert-800`,
        className
      )}
    >
      {children}
    </label>
  );
};

FormLabel.displayName = `FormLabel`;

export default FormLabel;
