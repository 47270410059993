import React from "react";

import { twMerge } from "tailwind-merge";

type FormLabelSkeletonProps = {
  className?: string;
};

const FormLabelSkeleton = ({ className }: FormLabelSkeletonProps) => {
  return (
    <div className="py-2">
      <div
        className={twMerge(
          ` h-2 bg-gray-200 rounded-full w-36 animate-pulse`,
          className
        )}
      ></div>
    </div>
  );
};

export default FormLabelSkeleton;
