"use client";

import { useEffect, useState } from "react";
import { UseFormReturn } from "react-hook-form";

import { twMerge } from "tailwind-merge";

import { OrderType } from "@efarmz/efarmz-domain-typescript";

import { DeliveryWindowFormData } from "@/hooks/formData/useDeliveryWindowFormData";

import DeliveryFormCarrier from "./DeliveryFormCarrier";
import DeliveryFormLocation from "./DeliveryFormLocation";

type DeliveryWindowFormProps = {
  className?: string;
  form: UseFormReturn<DeliveryWindowFormData, object>;
  deliveryOptions: any;
  withDetails?: boolean;
  orderType?: OrderType;
};

const DeliveryWindowForm = ({
  className,
  form,
  deliveryOptions,
  withDetails = false,
  orderType = OrderType.ONESHOT,
}: DeliveryWindowFormProps) => {
  const {
    setFocus,
    setValue,
    watch,
    control,
    formState: { errors },
    getValues,
  } = form;

  const { zipcode, deliveryType, date, countryIso2, carrier, slot } = watch();

  const [datePreviousValue, setDatePreviousValue] = useState(date);
  const [countryIso2PreviousValue, setCountryIso2PreviousValue] =
    useState(countryIso2);

  const [zipcodePreviousValue, setZipcodePreviousValue] = useState(zipcode);
  const [mapDefaultCoordinates, setMapDefaultCoordinates] = useState({
    lat: 50.85,
    lng: 4.349,
  });

  useEffect(() => {
    if (datePreviousValue && date?.value !== datePreviousValue?.value) {
      setValue(`carrier`, null);
    }
    setDatePreviousValue(date);
  }, [setValue, date, datePreviousValue]);

  useEffect(() => {
    //setFocus(`zipcode`);
  }, [setFocus]);

  useEffect(() => {
    if (carrier?.metadata?.slot && carrier?.metadata?.slot !== slot) {
      setValue(`slot`, carrier?.metadata?.slot);
    }
  }, [carrier, setValue, slot]);

  //reset date on zipcode changed
  useEffect(() => {
    if (zipcode !== zipcodePreviousValue && deliveryOptions?.postcodes) {
      setValue(`date`, null);
      setValue(`carrier`, null);
      setValue(`addressLine1`, undefined, { shouldValidate: true });
      setValue(`addressLine2`, undefined, { shouldValidate: true });
      setValue(`details`, undefined);
      setValue(`city`, undefined, { shouldValidate: true });

      if (deliveryOptions) {
        const coordinates = deliveryOptions.postcodes.find(
          (postcode: any) => postcode.postcode === zipcode
        );

        if (coordinates) {
          setMapDefaultCoordinates({
            lat: parseFloat(coordinates.lat),
            lng: parseFloat(coordinates.lng),
          });
        }
      }
    }
    if (zipcode && zipcode?.length > 4) {
      setValue(`zipcode`, zipcode?.substring(0, 4));
    }
    setZipcodePreviousValue(zipcode);
  }, [setValue, setFocus, zipcode, zipcodePreviousValue, deliveryOptions]);

  //reset country
  useEffect(() => {
    if (countryIso2?.value !== countryIso2PreviousValue?.value) {
      setValue(`zipcode`, ``);
      setFocus(`zipcode`);
    }
    setCountryIso2PreviousValue(countryIso2);
  }, [
    countryIso2,
    countryIso2?.value,
    countryIso2PreviousValue?.value,
    setValue,
    setFocus,
  ]);

  return (
    <div
      // onSubmit={form.handleSubmit(handleSubmitForm)}
      className={twMerge(``, className)}
    >
      <div className="space-y-5 sm:space-y-0 sm:grid grid-cols-1 gap-x-6 gap-y-7 sm:grid-cols-6">
        {!withDetails && <DeliveryFormLocation form={form} />}

        <DeliveryFormCarrier
          form={form}
          withDetails={withDetails}
          orderType={orderType}
          deliveryOptions={deliveryOptions}
        />
      </div>
    </div>
  );
};

export default DeliveryWindowForm;
