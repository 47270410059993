import React, { Fragment } from "react";
import * as RPNInput from "react-phone-number-input";

import { E164Number } from "libphonenumber-js";
import { twMerge } from "tailwind-merge";

import SelectInput from "@efarmz/efarmz-react-commons/esm/components/forms/SelectInput";
import TextInput from "@efarmz/efarmz-react-commons/esm/components/forms/TextInput";

import Flag from "@/components/atoms/Flag";

type PhoneInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  `onChange` | `value`
> &
  Omit<RPNInput.Props<typeof RPNInput.default>, `onChange`> & {
    onChange?: (value: RPNInput.Value) => void;
  } & { error?: string };

const PhoneInput: React.ForwardRefExoticComponent<PhoneInputProps> =
  React.forwardRef<React.ElementRef<typeof RPNInput.default>, PhoneInputProps>(
    ({ className, onChange, error, ...props }, ref) => {
      return (
        <>
          <RPNInput.default
            ref={ref}
            className={twMerge(
              `flex rounded-md border`,
              error ? `border-red-500` : `border-gray-300`,
              className
            )}
            countrySelectComponent={CountrySelect}
            inputComponent={InputComponent}
            /**
             * Handles the onChange event.
             *
             * react-phone-number-input might trigger the onChange event as undefined
             * when a valid phone number is not entered. To prevent this,
             * the value is coerced to an empty string.
             *
             * @param {E164Number | undefined} value - The entered value
             */
            international
            onChange={(value) => onChange?.(value || (`` as E164Number))}
            {...props}
          />
          {error && (
            <span className="text-xs text-red-500 block mt-2">{error}</span>
          )}
        </>
      );
    }
  );
PhoneInput.displayName = `PhoneInput`;

const InputComponent = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ className, ...props }, ref) => (
  <TextInput
    {...props}
    ref={ref}
    className="!ring-0 !border-transparent !border-0 active:!border-0 focus:!border-0 hover:!border-0"
  />
));

InputComponent.displayName = `InputComponent`;

type CountrySelectOption = { label: string; value: RPNInput.Country };

type CountrySelectProps = {
  disabled?: boolean;
  value: RPNInput.Country;
  onChange: (value: RPNInput.Country) => void;
  options: CountrySelectOption[];
};

const CountrySelect = ({
  disabled,
  value,
  onChange,
  options,
}: CountrySelectProps) => {
  const formattedOptions = options
    ?.filter((option) => !!option?.value)
    .map((option) => ({
      value: option.value,
      label: option.value,
      icon: <Flag code={option.value || `FR`} className="w-5" />,
    }));

  const formattedValue = formattedOptions?.find(
    (option) => option.value === value
  );

  return (
    <SelectInput
      options={formattedOptions}
      value={formattedValue}
      isSearchable
      className="max-w-24"
      inputProps={{
        className: `!border-0`,
      }}
      onChange={(option: any) => {
        onChange(option?.value);
      }}
    />
  );
};

export default PhoneInput;
