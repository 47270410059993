"use client";

import { FormLabelSkeleton } from "@/components/forms/FormLabel";
import SelectInputSkeleton from "@/components/forms/SelectInputSkeleton";

import DeliveryFormCarrierHomeSkeleton from "./DeliveryFormCarrierHomeSkeleton";

type DeliveryFormCarrierSkeletonProps = {
  withDetails?: boolean;
};

const DeliveryFormCarrierSkeleton = ({
  withDetails = false,
}: DeliveryFormCarrierSkeletonProps) => {
  return (
    <>
      {!withDetails && (
        <div className="col-span-6">
          <FormLabelSkeleton />
          <div className="mt-2">
            <SelectInputSkeleton />
          </div>
        </div>
      )}

      <div className="col-span-6">
        <FormLabelSkeleton />
        <div className="mt-2">
          <div className="grid grid-cols-2 gap-2">
            <div className="h-28 bg-gray-50 border rounded-lg w-full" />
            <div className="h-28 bg-gray-50 border rounded-lg w-full" />
          </div>
        </div>
      </div>

      <DeliveryFormCarrierHomeSkeleton withDetails={withDetails} />
    </>
  );
};

export default DeliveryFormCarrierSkeleton;
