"use client";

import { Controller, UseFormReturn } from "react-hook-form";

import { useTranslations } from "next-intl";

import SelectInput from "@efarmz/efarmz-react-commons/esm/components/forms/SelectInput";
import TextInput from "@efarmz/efarmz-react-commons/esm/components/forms/TextInput";

import Flag from "@/components/atoms/Flag";

import FormLabel from "@/components/forms/FormLabel";

import { DeliveryWindowFormData } from "@/hooks/formData/useDeliveryWindowFormData";

type DeliveryFormLocationProps = {
  className?: string;
  form: UseFormReturn<DeliveryWindowFormData, object>;
};

const DeliveryFormLocation = ({ form }: DeliveryFormLocationProps) => {
  const {
    control,
    watch,
    formState: { errors },
  } = form;

  const { countryIso2 } = watch();

  const tKey = `components/DeliveryWindowForm`;
  const t = useTranslations();

  const countryOptions = [
    {
      value: `BE`,
      label: t(`countries.BE`),
      icon: <Flag code="BE" className="w-5" />,
    },
    {
      value: `LU`,
      label: t(`countries.LU`),
      icon: <Flag code="LU" className="w-5" />,
    },
  ];

  return (
    <>
      <div className="sm:col-span-3">
        <FormLabel htmlFor="coutryIso2">
          {t(`${tKey}.fields.country.label`)}
        </FormLabel>
        <div className="mt-2">
          <Controller
            control={control}
            name="countryIso2"
            render={({ field }) => (
              <SelectInput {...field} options={countryOptions} />
            )}
          />
        </div>
      </div>

      <div className="sm:col-span-3">
        <FormLabel htmlFor="zipcode">
          {t(`${tKey}.fields.zipcode.label`)}
        </FormLabel>
        <div className="mt-2">
          <Controller
            control={control}
            name="zipcode"
            rules={{
              required: {
                value: true,
                message: t(`common.form.errors.required`),
              },
              minLength: {
                value: 4,
                message: t(`common.form.errors.too-short`),
              },
            }}
            render={({ field }) => (
              <TextInput
                {...field}
                error={errors?.zipcode?.message}
                preprend={countryIso2?.value === `LU` ? `L-` : undefined}
              />
            )}
          />
        </div>
      </div>
    </>
  );
};

export default DeliveryFormLocation;
