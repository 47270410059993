"use client";

import { useEffect, useMemo } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";

import { format, parse, parseISO } from "date-fns";
import { useTranslations } from "next-intl";
import { twMerge } from "tailwind-merge";

import OptionType from "@/types/OptionType";

import SelectInput from "@efarmz/efarmz-react-commons/esm/components/forms/SelectInput";
import TextArea from "@efarmz/efarmz-react-commons/esm/components/forms/TextArea";
import TextInput from "@efarmz/efarmz-react-commons/esm/components/forms/TextInput";

import Flag from "@/components/atoms/Flag";

import FormLabel from "@/components/forms/FormLabel";
import PhoneInput from "@/components/forms/PhoneInput";

import { DeliveryWindowFormData } from "@/hooks/formData/useDeliveryWindowFormData";
import useAuth from "@/hooks/useAuth";

import getNextCarrierDeliveryDates from "@/utils/shared/getNextCarrierDeliveryDates";

type DeliveryFormCarrierHomeProps = {
  className?: string;
  form: UseFormReturn<DeliveryWindowFormData, object>;
  carrierByDeliveryDate: any;
  withDetails?: boolean;
  deliveryOptions: any;
  dateOptions: any[];
};

const DeliveryFormCarrierHome = ({
  className,
  form,
  carrierByDeliveryDate,
  deliveryOptions,
  dateOptions,
  withDetails = false,
}: DeliveryFormCarrierHomeProps) => {
  const {
    control,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const {
    date,
    addressLine1,
    addressLine2,
    city,
    zipcode,
    phone,
    details,
    carrier,
  } = watch();
  const tKey = `components/DeliveryWindowForm`;
  const t = useTranslations();
  const { user } = useAuth();
  const slotOptions = useMemo(() => {
    let slotOptions: OptionType<number>[] = [];

    if (
      date?.value &&
      carrierByDeliveryDate &&
      carrierByDeliveryDate.hasOwnProperty(date?.value)
    ) {
      const slotSets: string[] = [];

      carrierByDeliveryDate[date.value].forEach((carrier: any) => {
        carrier.availabilities?.forEach((availabilitiy: any) => {
          try {
            const start = parseISO(availabilitiy.start);
            const end = parseISO(availabilitiy.end);

            const slot = `${format(start, `HH:mm`)} - ${format(end, `HH:mm`)}`;
            const key = `${slot}`;

            const nextDeliveryDates = date.value
              ? getNextCarrierDeliveryDates(
                  deliveryOptions,
                  {
                    id: carrier.carrier_id,
                  },
                  parse(date.value, `dd/MM/yyyy`, new Date()),
                  2,
                  slot
                )
              : [];

            if (
              format(start, `dd/MM/yyyy`) === date.value &&
              !slotSets.includes(key)
            ) {
              slotSets.push(key);
              slotOptions.push({
                metadata: {
                  slot,
                  nextDeliveryDates,
                },
                value: carrier?.id,
                label: slot,
              });
            }
          } catch (e) {}
        });
      });
    }

    slotOptions = slotOptions.sort((a, b) => {
      const dateA = parse(
        (a.metadata?.slot as string)?.split(` - `)[0],
        `HH:mm`,
        new Date()
      );

      const dateB = parse(
        (b.metadata?.slot as string)?.split(` - `)[0],
        `HH:mm`,
        new Date()
      );

      return dateA.getTime() - dateB.getTime();
    });

    return slotOptions;
  }, [carrierByDeliveryDate, date?.value, deliveryOptions]);

  useEffect(() => {
    const keys = slotOptions.map((option) => option.value);

    if (
      (!carrier || (carrier?.value && !keys.includes(carrier.value!))) &&
      slotOptions?.length > 0
    ) {
      let carrierValue = slotOptions[0];
      if (user?.lastDeliveryWindow?.carrier?.id) {
        const hasValue = slotOptions.find(
          (option) => option.value === user?.lastDeliveryWindow?.carrier?.id
        );

        if (hasValue) {
          carrierValue;
        }
      }
      setValue(`carrier`, carrierValue);
    }
  }, [carrier, setValue, slotOptions, user?.lastDeliveryWindow?.carrier?.id]);

  const countryOptions = [
    {
      value: `BE`,
      label: t(`countries.BE`),
      icon: <Flag code="BE" className="w-5" />,
    },
    {
      value: `LU`,
      label: t(`countries.LU`),
      icon: <Flag code="LU" className="w-5" />,
    },
  ];

  return (
    <>
      {withDetails && (
        <>
          <div className="col-span-6">
            <FormLabel htmlFor="addressLine1">
              {t(`${tKey}.fields.address.dialog.fields.addressLine1.label`)}
            </FormLabel>
            <div className="mt-3">
              <Controller
                control={control}
                name="addressLine1"
                rules={{
                  required: {
                    value: true,
                    message: t(`common.form.errors.required`),
                  },
                  pattern: {
                    value: /[0-9]+/,
                    message: t(`common.form.errors.missing-street-number`),
                  },
                }}
                render={({ field }) => (
                  <TextInput
                    {...field}
                    error={errors?.addressLine1?.message as any}
                    maxLength={249}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-span-6">
            <FormLabel htmlFor="addressLine2">
              {t(`${tKey}.fields.address.dialog.fields.addressLine2.label`)}
            </FormLabel>
            <div className="mt-3">
              <Controller
                control={control}
                name="addressLine2"
                render={({ field }) => (
                  <TextInput
                    {...field}
                    error={errors?.addressLine2?.message as any}
                    maxLength={249}
                    placeholder="ex: Sonnette, boîte etc..."
                  />
                )}
              />
            </div>
          </div>
          <div className="col-span-2">
            <FormLabel htmlFor="zipcode">
              {t(`${tKey}.fields.address.dialog.fields.zipcode.label`)}
            </FormLabel>
            <div className="mt-3">
              <Controller
                control={control}
                name="zipcode"
                rules={{
                  required: {
                    value: true,
                    message: t(`common.form.errors.required`),
                  },
                }}
                render={({ field }) => <TextInput {...field} />}
              />
            </div>
          </div>
          <div className="col-span-4">
            <FormLabel htmlFor="city">
              {t(`${tKey}.fields.address.dialog.fields.city.label`)}
            </FormLabel>
            <div className="mt-3">
              <Controller
                control={control}
                name="city"
                rules={{
                  required: {
                    value: true,
                    message: t(`common.form.errors.required`),
                  },
                }}
                render={({ field }) => (
                  <TextInput {...field} error={errors?.city?.message as any} />
                )}
              />
            </div>
          </div>
          <div className="col-span-6">
            <FormLabel htmlFor="coutryIso2">
              {t(`${tKey}.fields.country.label`)}
            </FormLabel>
            <div className="mt-3">
              <Controller
                control={control}
                name="countryIso2"
                render={({ field }) => (
                  <SelectInput {...field} options={countryOptions} />
                )}
              />
            </div>
          </div>
          <div className="col-span-6">
            <FormLabel htmlFor="phone">
              {t(`${tKey}.fields.address.dialog.fields.phone.label`)}
            </FormLabel>
            <div className="mt-3">
              <Controller
                control={control}
                name="phone"
                rules={{
                  required: {
                    value: true,
                    message: t(`common.form.errors.required`),
                  },
                  validate: (value: string | undefined) => {
                    if (value) {
                      const isValid = isValidPhoneNumber(value);
                      if (isValid) {
                        return true;
                      }
                    }

                    return t(`common.form.errors.invalid-phone`);
                  },
                }}
                render={({ field }) => (
                  <PhoneInput
                    {...field}
                    error={errors?.phone?.message as any}
                  />
                )}
              />
            </div>
          </div>
          <div className="col-span-6">
            <FormLabel htmlFor="details">
              {t(`${tKey}.fields.address.dialog.fields.details.label`)}
            </FormLabel>
            <div className="mt-3">
              <Controller
                control={control}
                name="details"
                render={({ field }) => (
                  <TextArea
                    {...field}
                    error={errors?.details?.message as any}
                    maxLength={249}
                    placeholder="ex: Si absent, déposer la commande sur la terrasse.."
                  />
                )}
              />
            </div>
          </div>
          <div className="col-span-6 py-2">
            <h3 className="text-lg font-semibold leading-6 text-vert-800">
              Créneau de livraison
            </h3>
          </div>
        </>
      )}

      <div className="col-span-6">
        <FormLabel htmlFor="date">{t(`${tKey}.fields.date.label`)}</FormLabel>
        <div className="mt-3">
          <Controller
            control={control}
            name="date"
            rules={{
              required: {
                value: true,
                message: t(`common.form.errors.required`),
              },
            }}
            render={({ field }) => {
              return (
                <SelectInput
                  {...field}
                  options={dateOptions}
                  error={
                    errors?.date ? t(`common.form.errors.required`) : undefined
                  }
                  render={(option) => {
                    return (
                      <div key={option.value}>
                        <span
                          className={twMerge(
                            `block text-sm`,
                            option?.subtitle ? `text-vert-600 font-medium` : ``
                          )}
                        >
                          {option.label}
                        </span>
                        {option?.subtitle && (
                          <span className="block text-xs text-rouge-500 font-medium mt-1">
                            {option.subtitle}
                          </span>
                        )}
                      </div>
                    );
                  }}
                />
              );
            }}
          />
        </div>
      </div>

      <div className="col-span-6">
        <FormLabel htmlFor="carrier">
          {t(`${tKey}.fields.slot.label`)}
        </FormLabel>
        <div className="mt-3">
          <Controller
            control={control}
            name="carrier"
            rules={{
              required: {
                value: true,
                message: t(`common.form.errors.required`),
              },
            }}
            render={({ field }) => {
              return (
                <SelectInput
                  {...field}
                  options={slotOptions}
                  error={errors?.carrier?.message as any}
                />
              );
            }}
          />
        </div>
      </div>
    </>
  );
};

export default DeliveryFormCarrierHome;
