"use client";
import { useEffect, useMemo } from "react";
import { Controller, UseFormReturn } from "react-hook-form";
import { HiOutlineHome, HiOutlineMapPin } from "react-icons/hi2";

import dynamic from "next/dynamic";
import { useLocale, useTranslations } from "next-intl";

import { OrderableItemType, OrderType } from "@efarmz/efarmz-domain-typescript";
import useCurrency from "@efarmz/efarmz-react-commons/esm/hooks/useCurrency";

import FormLabel from "@/components/forms/FormLabel";
import RadioCardInput from "@/components/forms/RadioCardInput";

import { DeliveryWindowFormData } from "@/hooks/formData/useDeliveryWindowFormData";
import usePrevious from "@/hooks/usePrevious";

import DeliveryFormCarrierDropOffPointSkeleton from "./DeliveryFormCarrierDropOffPointSkeleton";
import DeliveryFormCarrierHome from "./DeliveryFormCarrierHome";
import DeliveryFormCarrierUnavailable from "./DeliveryFormCarrierUnavailable";
import getDeliveryDataFromDeliveryOptions from "./getDeliveryDataFromDeliveryOptions";

import getNextDeliveryDate from "@/utils/shared/getNextDeliveryDate";

const DeliveryFormCarrierDropOffPoint = dynamic(
  () => import(`./DeliveryFormCarrierDropOffPoint`),
  {
    loading: () => <DeliveryFormCarrierDropOffPointSkeleton />,
  }
);

type DeliveryFormCarrierProps = {
  className?: string;
  form: UseFormReturn<DeliveryWindowFormData, object>;
  withDetails?: boolean;
  orderType?: OrderType;
  deliveryOptions: any;
};

const DeliveryFormCarrier = ({
  className,
  form,
  withDetails = false,
  orderType = OrderType.ONESHOT,
  deliveryOptions,
}: DeliveryFormCarrierProps) => {
  const {
    setFocus,
    setValue,
    watch,
    control,
    formState: { errors },
    getValues,
  } = form;

  const locale = useLocale();

  const currency = useCurrency();

  const { zipcode, deliveryType, countryIso2, date } = watch();
  const previousDeliveryType = usePrevious(deliveryType);
  const previousDate = usePrevious(date);

  const tKey = `components/DeliveryWindowForm`;
  const t = useTranslations();

  useEffect(() => {
    if (
      previousDeliveryType &&
      previousDeliveryType?.value !== deliveryType?.value
    ) {
      setValue(`carrier`, null);
    }
  }, [deliveryType, previousDeliveryType, setValue]);

  const deliveryTypeOptions = [
    {
      value: `HOME`,
      label: t(`dataset.DeliveryType.HOME`),
      subtitle: t(`${tKey}.fields.delivery-type.options.0.subtitle`, {
        price: currency.format(80),
      }),
      icon: HiOutlineHome,
    },

    {
      value: `DROP_OFF_POINT`,
      label: t(`dataset.DeliveryType.DROP_OFF_POINT`),
      subtitle: t(`${tKey}.fields.delivery-type.options.1.subtitle`),
      icon: HiOutlineMapPin,
    },
  ];

  const { carriers, carrierByDeliveryDate, dateOptions }: any = useMemo(() => {
    const shortDeliveryDate = getNextDeliveryDate({
      type: OrderableItemType.PRODUCT,
    });
    const longDeliveryDate = getNextDeliveryDate({
      type: OrderableItemType.MEAL,
    });

    if (zipcode && zipcode?.length > 3 && countryIso2?.value) {
      return getDeliveryDataFromDeliveryOptions({
        locale,
        shortDeliveryDate,
        longDeliveryDate,
        deliveryOptions,
        countryIso2: countryIso2?.value,
        zipcode,
        orderType,
      });
    }
    return {};
  }, [countryIso2?.value, deliveryOptions, orderType, locale, zipcode]);

  useEffect(() => {
    if ((zipcode?.length || 0) > 4 && dateOptions.length > 0 && !date) {
      setValue(`date`, dateOptions[0]);
    }
  }, [
    date,
    dateOptions,
    deliveryType,
    previousDeliveryType,
    setValue,
    zipcode?.length,
  ]);

  /*
  useEffect(() => {
    if (!isPending) {
      const keys = Object.keys(carrierByDeliveryDate);

      if (!date && keys?.length > 0 && dateOptions.length > 0) {
        setValue(`date`, dateOptions[0]);
      }
      if (date && keys.length === 0) {
        
        //setValue(`date`, null);
        
      }
      if (
        date &&
        date?.value &&
        keys?.length > 0 &&
        !keys.includes(date.value)
      ) {
        setValue(`date`, dateOptions[0]);
      }
    }
  }, [
    date?.value,
    carrierByDeliveryDate,
    date,
    dateOptions,
    setValue,
    isPending,
  ]);
  */

  if (
    dateOptions?.length === 0 &&
    (zipcode?.length || 0) >= 4 &&
    !withDetails
  ) {
    return (
      <div className="h-[300px] w-full col-span-6">
        <DeliveryFormCarrierUnavailable
          title={t(`${tKey}.errors.unavailable-delivery.title`)}
          subtitle={t(`${tKey}.errors.unavailable-delivery.subtitle`)}
        />
      </div>
    );
  }

  return (
    <>
      <div className="col-span-6">
        <FormLabel htmlFor="deliveryType">
          {t(`${tKey}.fields.delivery-type.label`)}
        </FormLabel>
        <div className="mt-3">
          <Controller
            control={control}
            name="deliveryType"
            render={({ field }) => (
              <RadioCardInput
                {...(field as any)}
                options={deliveryTypeOptions}
                className="block space-y-3 sm:space-y-0 sm:grid"
                itemProps={{
                  className: `w-full sm:w-auto`,
                }}
              />
            )}
          />
        </div>
      </div>

      <>
        {deliveryType?.value === `DROP_OFF_POINT` && (
          <DeliveryFormCarrierDropOffPoint
            form={form}
            deliveryOptions={deliveryOptions || []}
            withDetails={withDetails}
            dateOptions={dateOptions || []}
          />
        )}
        {deliveryType?.value === `HOME` && (
          <DeliveryFormCarrierHome
            form={form}
            carrierByDeliveryDate={carrierByDeliveryDate}
            dateOptions={dateOptions || []}
            withDetails={withDetails}
            deliveryOptions={deliveryOptions}
          />
        )}
      </>
    </>
  );
};

export default DeliveryFormCarrier;
