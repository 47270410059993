import { twMerge } from "tailwind-merge";

import DeliveryIcon from "../DeliveryIcon";

type DeliveryFormCarrierUnavailableProps = {
  className?: string;
  title: string;
  subtitle: string;
};

const DeliveryFormCarrierUnavailable = ({
  className,
  title,
  subtitle,
}: DeliveryFormCarrierUnavailableProps) => {
  return (
    <div
      className={twMerge(
        `rounded-md bg-menthe-100 py-8 px-6 col-span-6`,
        className
      )}
    >
      <div className="flex items-center">
        <div className="flex-shrink-0">
          <DeliveryIcon className="w-28" />
        </div>
        <div className="ml-6">
          <h3 className="text-MD font-medium text-vert-500">{title}</h3>
          <div className="mt-2 text-sm text-vert-500/80">
            <p>{subtitle}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeliveryFormCarrierUnavailable;
