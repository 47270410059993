import { twMerge } from "tailwind-merge";

type SelectInputSkeletonProps = {
  className?: string;
};

const SelectInputSkeleton = ({ className }: SelectInputSkeletonProps) => {
  return (
    <div
      className={twMerge(
        `h-[39.5px] bg-gray-100 rounded-lg border animate-pulse`,
        className
      )}
    />
  );
};

export default SelectInputSkeleton;
