"use client";

import { DeliveryFormCarrierSkeleton } from "./DeliveryFormCarrier";
import { DeliveryFormLocationSkeleton } from "./DeliveryFormLocation";

type DeliveryWindowFormSkeletonProps = {
  withDetails?: boolean;
};

const DeliveryWindowFormSkeleton = ({
  withDetails = false,
}: DeliveryWindowFormSkeletonProps) => {
  return (
    <div className=" space-y-5 sm:space-y-0 sm:grid grid-cols-1 gap-x-6 gap-y-7 sm:grid-cols-6">
      {withDetails ? (
        <DeliveryFormCarrierSkeleton withDetails={withDetails} />
      ) : (
        <>
          <DeliveryFormLocationSkeleton />
          <DeliveryFormCarrierSkeleton withDetails={withDetails} />
        </>
      )}
    </div>
  );
};

export default DeliveryWindowFormSkeleton;
