import React, { Ref, useEffect, useRef, useState } from "react";
import { BiSolidMessageSquareCheck } from "react-icons/bi";

import * as RadioGroup from "@radix-ui/react-radio-group";
import { twMerge } from "tailwind-merge";

import OptionType from "@/types/OptionType";

type RadioCardInputProps = Omit<
  React.InputHTMLAttributes<HTMLInputElement>,
  `value` | `defaultValue` | `dir` | `onChange`
> & {
  value: OptionType<any>;
  direction?: `horizontal` | `vertical`;
  showLabel?: true;
  showSubtitle?: true;
  showIcon?: true;
  onChange: (newValue: OptionType<any>) => void;
  options: OptionType<string>[];
  error?: string;

  titleProps?: React.HTMLProps<HTMLSpanElement>;
  subtitleProps?: React.HTMLProps<HTMLSpanElement>;
  iconProps?: React.HTMLProps<HTMLDivElement>;
  itemProps?: Omit<
    React.HTMLProps<HTMLButtonElement>,
    `onClick` | `value` | `type` | `ref`
  >;
};

const RadioCardInput = React.forwardRef(
  (props: RadioCardInputProps, ref: React.ForwardedRef<HTMLInputElement>) => {
    const {
      value,
      className,
      options,
      onChange,
      direction = `horizontal`,
      error,
      showLabel = true,
      showSubtitle = true,
      showIcon = true,
      titleProps = {},
      subtitleProps = {},
      iconProps = {},
      itemProps = {},
      ...other
    } = props;

    const { className: titleClassName, ...otherTitleProps } = titleProps;
    const { className: subtitleClassName, ...otherSubtitleProps } =
      subtitleProps;
    const { className: itemClassName, ...otherItemProps } = itemProps;
    const { className: iconClassName, ...otherIconProps } = iconProps;
    const [oldValue, setOldValue] = useState(value);

    const handleChange = (e: React.MouseEvent<HTMLButtonElement>) => {
      const newOption = options.find((option) => {
        return option.value?.toString() === e.currentTarget.value;
      });

      if (newOption && !newOption.disabled) {
        onChange(newOption!);
      }
    };

    const selectedRef = useRef<HTMLButtonElement | undefined>();

    useEffect(() => {
      if (value?.value !== oldValue?.value) {
        if (selectedRef.current) {
          selectedRef.current.scrollIntoView({
            behavior: `smooth`,
            block: `nearest`,
            inline: `center`,
          });
        }
        setOldValue(value);
      }
    }, [value, oldValue]);

    const Items = options.map((option) => {
      return (
        <RadioGroup.Item
          checked={value?.value === option.value}
          ref={
            value?.value === option.value
              ? (selectedRef as Ref<HTMLButtonElement> | undefined)
              : null
          }
          key={option.value}
          value={option.value as string}
          onClick={handleChange}
          className={twMerge(
            `relative text-left flex flex-col rounded-lg bg-white p-4 shadow-sm focus:outline-none border`,
            `data-[state=checked]:bg-menthe-100 data-[state=checked]:text-choux-500 data-[state=checked]:border-choux-500`,
            option.disabled ? `cursor-not-allowed` : `cursor-pointer `,
            itemClassName,
            error && `border border-rouge-500`
          )}
          {...otherItemProps}
        >
          {showIcon && option.icon && (
            <option.icon
              {...otherIconProps}
              className={twMerge(`text-2xl mb-2`, iconClassName)}
            />
          )}
          <div>
            {showLabel &&
              (!React.isValidElement(option.subtitle) ? (
                <span
                  className={twMerge(
                    `block text-md font-medium`,
                    option.disabled ? `text-gray-300` : `text-current`,
                    titleClassName
                  )}
                >
                  {option.label}
                </span>
              ) : (
                option.label
              ))}

            {showSubtitle &&
              (!React.isValidElement(option.subtitle) ? (
                <span
                  className={twMerge(
                    `mt-1 flex items-center text-sm`,
                    option.disabled ? `text-gray-300` : `text-gray-500`,
                    subtitleClassName
                  )}
                >
                  {option.subtitle}
                </span>
              ) : (
                option.subtitle
              ))}
          </div>

          <RadioGroup.Indicator>
            <BiSolidMessageSquareCheck
              className={twMerge(
                `h-5 w-5 text-choux-500 absolute top-2 right-2`
              )}
              aria-hidden="true"
            />
          </RadioGroup.Indicator>

          <RadioGroup.Indicator>
            <span
              className={twMerge(
                `border-2`,
                `border-choux-500`,
                `pointer-events-none absolute -inset-px rounded-lg`
              )}
              aria-hidden="true"
            />
          </RadioGroup.Indicator>
        </RadioGroup.Item>
      );
    });
    return (
      <RadioGroup.Root
        className={twMerge(
          `grid grid-cols-none grid-flow-col auto-cols-auto gap-x-6 gap-y-8`,
          direction === `vertical` && `flex flex-col gap-y-2`,
          className
        )}
        aria-label="View density"
        ref={ref}
        {...other}
        value={value?.value as string}
      >
        {Items}
      </RadioGroup.Root>
    );
  }
);

RadioCardInput.displayName = `RadioCardInput`;

export default RadioCardInput;
